<template>
  <div>
    <div class="container-fluid pt-0 row mx-0 bg-primary">
      <!-- 业务介绍开始 -->
      <div
        class="
          container-fluid
          card
          col-sm-12 col-md-12 col-lg-10 col-xl-10
          mycard-scale
          my-4
        "
      >
        <!-- Grid column -->
        <h5 class="card-title font-weight-bold my-3">
          自治区交通厅领导莅临桂运宝检查平台工作
        </h5>
        <p class="card-text p2em">
          2021年1月8日，广西壮族自治区交通运输厅办公室综合运输管理处领导莅临广西桂运宝科技有限公司，现场检查货运企业线上服务能力，以及对桂运宝物流综合服务能力的认定。
        </p>
        <p class="p2em">
          交通厅领导一行在参观广西桂运宝科技有限公司办公室后共聚一堂，深入探讨货运平台发展趋势、金融解决方案、系统解决方案及运营方案。
        </p>
        <img class="image-640 my-4" src="@/assets/images/meating1.jpg" alt="" />
        <p class="p2em">
          在会议现场，广西桂运宝向自治区交通厅领导深入介绍了包括：物流行业现状及趋势、货运发展现状、货运政策、申报流程、系统平台的选择、税务统筹规划、物流金融、增值服务、货运的盈利模式、发展趋势以及货运生态构建等。
        </p>
        <p class="p2em">
          广西桂运宝总经理为在场的运输处领导做了桂运宝货运运营模式、平台系统、及物流行业规范化管理的方案等分享介绍。
        </p>
        <img class="image-640 my-4" src="@/assets/images/sys-map.jpg" alt="" />
        <h5 class="card-title font-weight-bold">
          广西桂运宝货运平台为企业提供全方位管家式服务
        </h5>
        <p class="card-text p2em">
          广西桂运宝货运平台增值服务包含为货运平台内企业提供运营指导、车辆调遣、财税服务、车辆监控、在线支付、供应链金融、ETC、油品、保险、维修等一条龙全方位服务。
        </p>
        <h5 class="card-title font-weight-bold">运营服务</h5>
        <p class="card-text p2em">
          一站式服务体系，货运专有绿色通道一条龙服务。
        </p>
        <h5 class="card-title font-weight-bold">车辆监控</h5>
        <p class="card-text p2em">
          选用国际领先的智能监控、管理平台车辆，GPS、北斗双定位，行驶轨迹、车辆状态一目了然，司机疲劳驾驶、危险动作，实时监控；同时也支持对接国内主流车辆定位、监控平台，灵活多样，适应性强。
        </p>
        <h5 class="card-title font-weight-bold">金融服务</h5>
        <p class="card-text p2em">
          通过司机、货主钱包、在线支付、供应链金融等互联网+物流+金融产品，为平台企业提供完备的物流金融服务。
        </p>
        <img class="image-640 my-4" src="@/assets/images/worknet.jpg" alt="" />
        <h5 class="card-title font-weight-bold">车后市场</h5>
        <p class="card-text p2em">
          在线提供ETC、油品、货运保险、配件、维修等车后服务，实现充值、结算、付款、开票的自动化、在线化，规范企业管理流程、提升业务效率、推动行业管理水平。
        </p>
        <img class="image-640" src="@/assets/images/worknet2.jpg" alt="" />
        <h5 class="card-title font-weight-bold">货运服务价值明显</h5>
        <h5 class="card-title font-weight-bold">轻资产扩张</h5>
        <p class="card-text p2em">
          通过无车承运，整合社会车辆及货源，企业不再一定要自己买车，雇佣司机，即可展开营运业务，实现轻资产扩张，做大做强。
        </p>
        <h5 class="card-title font-weight-bold">独立运营</h5>
        <p class="card-text p2em">
          可通过自身平台向上整合货源，向下整合运力，形成公共运力池，打通独立的运营核心链条。
        </p>
        <h5 class="card-title font-weight-bold">税务合规</h5>
        <p class="card-text p2em">
          货运提供了个体司机带票运输解决方案，物流企业合法取得个体司机运输发票，避免“白条”风险，确保数据真实，保证税务合规。
        </p>
        <h5 class="card-title font-weight-bold">降本增效</h5>
        <p class="card-text p2em">
          通过货运，将大幅提升物流企业信息化管理能力，助推企业成长为线下货运运输组织能力与线上平台化数据运营能力相结合的复合型企业，实现降本增效。
        </p>
        <h5 class="card-title font-weight-bold">政策支持</h5>
        <p class="p2em">
          自治区政府大力扶持货运企业，政府给予财政奖励优惠、免费办公场地等政策优惠。
        </p>
        <h5 class="card-title font-weight-bold">低成本集采</h5>
        <p class="p2em">
          将物流产业公司集群、规模效应带来的ETC、油、维修、配件及年检等增值服务集采价格优势。
        </p>
        <img class="image-640 my-4" src="@/assets/images/meating2.jpg" alt="" />
        <p class="p2em">
          在随后的环节，自治区交通运输厅综合运输管理处领导向桂运宝的工作人员详细说明了自治区下达的安全生产管理条例，并督促桂运宝货运平台因严格按自治区要求，管理平台客户及用户。
        </p>
        <p class="p2em">
          从年初货运平台搭建完成至今，短短半年间已有超1000位物流司机及近百家物流企业入桂运宝网络平台。
        </p>
      </div>
      <!-- Grid column -->
    </div>
  </div>
  <!-- 业务介绍结束 -->
</template>

<script>
// @ is an alias to /src

export default {
  name: "News",
};
</script>
 <style>
</style>
