<template>
  <div>
    <div class="container-fluid pt-0 row mx-0 bg-primary">
      <!-- 业务介绍开始 -->
      <div
        class="
          container-fluid
          mx-auto
          card
          col-sm-12 col-md-12 col-lg-10 col-xl-10
          mycard-scale
          my-4
        "
      >
        <h5 class="card-title font-weight-bold my-3">
          <i class="fa fa-building me-3" aria-hidden="true"></i>公司简介
        </h5>
        <p class="card-text p2em">
          广西桂运宝科技有限公司坐落于广西重工业城市柳州市，公司致力于公路货物运输业。是通过“线上资源整合、线下物流高效运行"的货运平台公司。
          原公司业务范围包括：成品钢材、板材、废钢、矿业、煤、快消品等。
        </p>
        <p class="p2em">
          合作客户包含广西恒达电机、海螺水泥厂、柳州龙昌贸易有限公司等等数十家企业。
        </p>
        <p class="p2em">
          桂运宝货运平台利用“物流+互联网+北斗”理念打造了O2O物流服务与信息平台，完成“一站式服务、一点发全国”的网络布局，实现物流与运力数据资源的聚合、共享和高效运营。
        </p>
        <h5 class="card-title font-weight-bold">
          <i class="fa fa-laptop me-3" aria-hidden="true"></i>桂运宝货运平台介绍
        </h5>
        <p class="card-text p2em">
          根据2019年9月9日国家交通运输部
          国家税务总局关于印发《网络平台道路货物运输经营管理暂行办法》的规定货运系统平台需要具备信息发布、线上交易、全程监控、金融支付、咨询投诉、在线评价、查询统计和数据调取功能，广西桂运宝科技有限公司应国家政策要求，开发组建了桂运宝货运平台来服务国内物流货运行业。
        </p>
        <img class="image-640 my-4" src="@/assets/images/platform.jpg" alt="" />
        <p class="p2em">
          通过本地独立化部署和云部署方式，开放对外接口与本省监测平台进行对接上传运单和资金流水单信息；通过PC端、移动端和大屏显示的方式为货主企业、平台管理者和承运商司机提供服务，从运单建立、到流转配载到线上资金结算一体化流程线上完成。
        </p>
        <img class="image-640 my-4" src="@/assets/images/sys-map.jpg" alt="" />
        <p class="p2em">
          桂运宝网络运输全过程的透明化管理，是新物流时代平台企业，已经实现了对运输、交易全过程的实时监控和动态管理，能够对运输货物轨迹、货物状态、货单明细等数据进行追踪记录，不仅具备全面管控和全自动风控功能，而且进一步提高了信息安全管理能力。
        </p>
        <p class="p2em">
          广西桂运宝货运平台主营业务以货运、普通货运、货物专用运输（集装箱）、大型物件运输为主，现已拥有道路货物运输经营许可证（含货运）、北斗定位与导航分理级及三级等保等资质。为积极响应国家在交通运输领域实现供给侧结构性改革的战略布局，桂运宝自成立以来始终坚持以货运平台为载体，综合应用互联网、物联网、北斗定位与导航、大数据等技术，通过科技创新、商业模式和业务模式创新，推动货物运输行业实现信息化、数字化、规模化。目前，桂运宝货运平台应用“物流+互联网+北斗”理念打造了O2O物流服务与信息平台，完成“一站式服务、一点发全国”的网络布局，实现物流与运力数据资源的聚合、共享和高效运营。
        </p>
      </div>
      <!-- Grid column -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data: function () {
    return {
      msg: String,
    };
  },
  name: "About",
  mounted() {},
};
</script>
 <style>
</style>
